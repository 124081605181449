/* variables */
$header-font-family: 'Arial', sans-serif;
$body-font-family: 'Open Sans', sans-serif;

/* font family */
.alt-font {font-family: $header-font-family; font-weight: 500;}
.main-font {font-family: $body-font-family;}

/* heading */
h1, h2, h3, h4, h5, h6 {margin:0 0 20px; padding:0; letter-spacing: 0; font-weight: 500; font-family: $header-font-family; color: #232323; line-height: normal;}
h1 {font-size:42px;}
h2 {font-size:34px;}
h3 {font-size:32px;}
h4 {font-size:30px;}
h5 {font-size:26px;}
h6 {font-size:22px;}

.footer-social-icons ul li {
  border-color: white;
}

.footer-social-icons ul li a {
  color: white;
}

header{
  z-index: 9999;
}

.navbar-nav > li > a {
  font-size: 14px;
}

.navbar-nav > li > a:hover,
.navbar-nav > li.has-sub > a:hover,
.navbar-nav > li.has-sub:hover > a,
.navbar-nav > li.has-sub.active > a,
.navbar-nav > li.active > a{
  color: $PrimaryRgbColor;
}

.navbar-nav li > ul {
  background: $PrimaryColor;
}

.navbar-nav li ul li a {
  color: #fff;
  font-size: 14px;
}

.menu_area .navbar-brand {
  padding: 0;
}

.navbar-toggler {
  background-color: $PrimaryColor;
}

#top-bar {
  background-color: #f5f5f5;
}

#top-bar li, #top-bar .top-social-icon i {
  color: #6f6f6f;
}

.top-bar-info li {
  font-size: 14px;
}

.footer-title-style3-grey {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 28px;
  color: #939393;
}

.footer-bar {
  background-color: $PrimaryRgbColor;
  color: white;
}

.footer-list {
  li {
    a {
      &:hover {
        color:$PrimaryColor;
        &:before {
          color: $PrimaryColor;
        }
      }
    }
  }
}

table.nice-table {
  width: 100%;
  tr {
    td {
      padding: .3rem;
      border: 1px solid #ccc;
    }
    &:nth-child(2n){
      background: #eaeaea;
    }
  }
}


@media screen and (min-width: 992px) {
  .scrollHeader .navbar-brand img {
    max-height: 50px;
  }
}
